// FLEX

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

// PADDING

.p-1 {
  padding: .25em;
}

.px-1 {
  padding-left: .25em;
  padding-right: .25em;
}

.py-1 {
  padding-top: .25em;
  padding-bottom: .25em;
}

.ps-1 {
  padding-left: .25em;
}

.pe-1 {
  padding-right: .25em;
}

.pt-1 {
  padding-top: .25em;
}

.pb-1 {
  padding-bottom: .25em;
}

.p-2 {
  padding: .5em;
}

.px-2 {
  padding-left: .5em;
  padding-right: .5em;
}

.py-2 {
  padding-top: .5em;
  padding-bottom: .5em;
}

.ps-2 {
  padding-left: .5em;
}

.pe-2 {
  padding-right: .5em;
}

.pt-2 {
  padding-top: .5em;
}

.pb-2 {
  padding-bottom: .5em;
}

.p-3 {
  padding: .75em;
}

.px-3 {
  padding-left: .75em;
  padding-right: .75em;
}

.py-3 {
  padding-top: .75em;
  padding-bottom: .75em;
}

.ps-3 {
  padding-left: .75em;
}

.pe-3 {
  padding-right: .75em;
}

.pt-3 {
  padding-top: .75em;
}

.pb-3 {
  padding-bottom: .75em;
}

.p-4 {
  padding: 1em;
}

.px-4 {
  padding-left: 1em;
  padding-right: 1em;
}

.py-4 {
  padding-top: 1em;
  padding-bottom: 1em;
}

.ps-4 {
  padding-left: 1em;
}

.pe-4 {
  padding-right: 1em;
}

.pt-4 {
  padding-top: 1em;
}

.pb-4 {
  padding-bottom: 1em;
}

.p-5 {
  padding: 2em;
}

.px-5 {
  padding-left: 2em;
  padding-right: 2em;
}

.py-5 {
  padding-top: 2em;
  padding-bottom: 2em;
}

.ps-5 {
  padding-left: 2em;
}

.pe-5 {
  padding-right: 2em;
}

.pt-5 {
  padding-top: 2em;
}

.pb-5 {
  padding-bottom: 2em;
}

// MARGIN

.m-1 {
  margin: .25em;
}

.mx-1 {
  margin-left: .25em;
  margin-right: .25em;
}

.my-1 {
  margin-top: .25em;
  margin-bottom: .25em;
}

.ms-1 {
  margin-left: .25em;
}

.me-1 {
  margin-right: .25em;
}

.mt-1 {
  margin-top: .25em;
}

.mb-1 {
  margin-bottom: .25em;
}

.m-2 {
  margin: .5em;
}

.mx-2 {
  margin-left: .5em;
  margin-right: .5em;
}

.my-2 {
  margin-top: .5em;
  margin-bottom: .5em;
}

.ms-2 {
  margin-left: .5em;
}

.me-2 {
  margin-right: .5em;
}

.mt-2 {
  margin-top: .5em;
}

.mb-2 {
  margin-bottom: .5em;
}

.m-3 {
  margin: .75em;
}

.mx-3 {
  margin-left: .75em;
  margin-right: .75em;
}

.my-3 {
  margin-top: .75em;
  margin-bottom: .75em;
}

.ms-3 {
  margin-left: .75em;
}

.me-3 {
  margin-right: .75em;
}

.mt-3 {
  margin-top: .75em;
}

.mb-3 {
  margin-bottom: .75em;
}

.m-4 {
  margin: 1em;
}

.mx-4 {
  margin-left: 1em;
  margin-right: 1em;
}

.my-4 {
  margin-top: 1em;
  margin-bottom: 1em;
}

.ms-4 {
  margin-left: 1em;
}

.me-4 {
  margin-right: 1em;
}

.mt-4 {
  margin-top: 1em;
}

mpb-4 {
  margin-bottom: 1em;
}

.m-5 {
  margin: 2em;
}

.mx-5 {
  margin-left: 2em;
  margin-right: 2em;
}

.my-5 {
  margin-top: 2em;
  margin-bottom: 2em;
}

.ms-5 {
  margin-left: 2em;
}

.me-5 {
  margin-right: 2em;
}

.mt-5 {
  margin-top: 2em;
}

.mb-5 {
  margin-bottom: 2em;
}
